<!-- <ngx-ui-loader></ngx-ui-loader> -->
<div class="wrapper" style="background-color: #f4f6f9;">

  <!-- navbar -->
  <nav class="main-header navbar navbar-expand navbar-dark navbar-danger"  *ngIf="this.loginInfo.loginInfo2" >
    <!-- <nav class="main-header navbar navbar-expand navbar-dark navbar-danger" *ngIf="login.getValue()" >-->
      <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" (click)="sidebar()"><i class="fas fa-bars fa-2x"></i></a>
        <!-- <a class="nav-link" data-widget="pushmenu" href="#" (click)="toggleNavbar()" ><i class="fas fa-bars fa-2x"></i></a> -->
      </li>
      <span id="usuario-top" style="font-size: 24px; color:#f8f9fa; font-weight: bold; margin-top: auto; margin-bottom: auto; line-height: 1; ">USUARIO</span>
    </ul>

    <!-- SEARCH FORM -->
<!--
    <form class="form-inline ml-3">
      <div class="input-group input-group-sm">
        <input class="form-control form-control-navbar" type="search" placeholder="Buscar" aria-label="Search">
        <div class="input-group-append">
          <button class="btn btn-navbar" type="submit">
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
    </form>
  -->

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Notifications Dropdown Menu -->
      <!--
      <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell fa-2x"></i>
          <span class="badge badge-warning navbar-badge" style="font-size:12px; margin-left: 3px; font-weight: bolder;">15</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">NOTIFICACIONES</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 10 Nuevas Garantias
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 5 Nuevas solicitudes
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 Aprobaciones 
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
        </div>
      </li>
      -->
      <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-slide="true" routerLink="Login" (click)="logout()" >
          <i class="fas fa-power-off fa-2x"></i>
        </a>
      </li>
    </ul>
  </nav>
<!-- /navbar-->

<!-- Main Sidebar Container Menu -->

<aside class="main-sidebar sidebar-dark-primary elevation-4" *ngIf="this.loginInfo.loginInfo2">
  <!-- <aside class="main-sidebar sidebar-dark-primary elevation-4" *ngIf="authenticationService.getTheBoolean()">-->
    <!-- Brand Logo -->
  <a href="#" class="brand-link elevation-4">
    <img src="../assets/imagenes/logo-garden.png" alt="GARDEN" class="brand-image img-circle elevation-5 mr-1" style="opacity: .8">
    <span class="brand-text font-weight-light ml-0"><strong>RUPO GARDEN</strong> </span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar" style="margin-top: 5px; padding-top: 8px;" #sidenav [ngClass]="{ 'sidebar-collapse': isCollapsed }" >
    <!-- Sidebar Menu -->
    <nav class="mt-5">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
        <li class="nav-item has-treeview menu-open">
        <a href="#" class="nav-link">
            <!-- <i class="nav-icon fas fa-tachometer-alt"></i>-->
            <p>
              <strong>MENU</strong>
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">

            <li class="nav-item crear-solicitud">
              <a  routerLink="garantia/tablero" routerLinkActive="active" (click)="sidebarMini()" class="nav-link" >
                <i class="fa fa-th-list nav-icon" aria-hidden="true"></i>
                <p>GARANTIAS</p>
              </a>
            </li>
            <li class="nav-item">
              <a  routerLink="garantia/pendientes" routerLinkActive="active" (click)="sidebarMini()" class="nav-link">
                <i class="fa fa-th-large nav-icon" aria-hidden="true"></i>
                <p>SOLICITUDES</p>
              </a>
            </li>
            <li class="nav-item admin user">
              <a  routerLink="garantia/usuarios" routerLinkActive="active" (click)="sidebarMini()" class="nav-link">
                <i class="fa fa-th-large nav-icon" aria-hidden="true"></i>
                <p>USUARIOS</p>
              </a>
            </li>

            <li class="nav-item admin user">
              <a  routerLink="garantia/sucursales" routerLinkActive="active" (click)="sidebarMini()" class="nav-link">
                <i class="fa fa-th-large nav-icon" aria-hidden="true"></i>
                <p>SUCURSALES</p>
              </a>
            </li>

            <li class="nav-item admin">
              <a  routerLink="garantia/reportes" routerLinkActive="active" (click)="sidebarMini()" class="nav-link">
                <i class="fa fa-th-large nav-icon" aria-hidden="true"></i>
                <p>REPORTES</p>
              </a>
            </li>

            <li class="nav-item admin">
              <a  routerLink="garantia/documentacion" routerLinkActive="active" (click)="sidebarMini()" class="nav-link">
                <i class="fa fa-th-large nav-icon" aria-hidden="true"></i>
                <p>DOCUMENTACION</p>
              </a>
            </li>

            <!--
            <li class="nav-item">
              <a routerLink="Solicitud/0" routerLinkActive="active" class="nav-link">
                <i class="fa fa-file nav-icon" aria-hidden="true"></i>
                <p>NUEVA SOLICITUD</p>
              </a>
            </li> -->

          </ul>
        </li>

        <li class="nav-item has-treeview menu-open">
          <a href="#" class="nav-link">
            <!--<i class="nav-icon fas fa-tachometer-alt"></i>-->
            <p>
              <strong>USUARIOS</strong>
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">

            <li class="nav-item has-treeview menu-open" id="ucAdministrador">
              <a  routerLinkActive="active" class="nav-link">
                <i class="far fa-user nav-icon "></i>
                <p>ADMINISTRADOR</p>
              </a>
              <ul class="nav nav-treeview ml-4" id="uAdministrador">
                <!--
                <li class="nav-item"> 
                  <a class="nav-link">
                    <i class="far fa-square nav-icon text-success"></i>
                    <p>Nestor Povigna</p>
                  </a> 
                </li> 
              -->
              </ul>
              
            </li>
            <li class="nav-item has-treeview menu-open" id="ucGarantia">
              <a  routerLinkActive="active" class="nav-link">
                <i class="far fa-user nav-icon "></i>
                <p>GARANTIA</p>
              </a>
              <ul class="nav nav-treeview ml-4" id="uGarantia">
                <!--
                <li class="nav-item"> 
                  <a class="nav-link">
                    <i class="far fa-square nav-icon text-success"></i>
                    <p>Nestor Povigna</p>
                  </a> 
                </li>
              -->
              </ul>

            </li>
            <li class="nav-item has-treeview menu-open" id="ucTaller">
              <a  routerLinkActive="active" class="nav-link">
                <i class="far fa-user nav-icon"></i>
                <p>JEFE TALLER</p>
              </a>
              <ul class="nav nav-treeview ml-4" id="uTaller">
                <!--
                <li class="nav-item"> 
                  <a class="nav-link">
                    <i class="far fa-square nav-icon text-success"></i>
                    <p>Nestor Povigna</p>
                  </a> 
                </li>
              -->
              </ul>

            </li>
            <li class="nav-item has-treeview menu-open" id="ucGrupo">
              <a  routerLinkActive="active" class="nav-link">
                <i class="far fa-user nav-icon"></i>
                <p>JEFES GRUPO</p>
              </a>
              <ul class="nav nav-treeview ml-4" id="uGrupo">
                <!--
                <li class="nav-item"> 
                  <a class="nav-link">
                    <i class="far fa-square nav-icon text-success"></i>
                    <p>Nestor Povigna</p>
                  </a> 
                </li>
              -->
              </ul>

            </li>

            <li class="nav-item has-treeview menu-open" id="ucRepuesto">
              <a  routerLinkActive="active" class="nav-link">
                <i class="far fa-user nav-icon"></i>
                <p>REPUESTOS</p>
              </a>
              <ul class="nav nav-treeview ml-4" id="uRepuesto">
                <!--
                <li class="nav-item"> 
                  <a class="nav-link">
                    <i class="far fa-square nav-icon text-success"></i>
                    <p>Nestor Povigna</p>
                  </a> 
                </li>
              -->
              </ul>

            </li>

          </ul>
        </li>


      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>

<!-- /Sidebar Container -->
<div class="content-wrapper" style="display: flex; flex-direction: column; min-height:100%; height:100%; background-color: #f4f6f9;">
  <!-- <div class="content-wrapper" style="display: flex; flex-direction: column; min-height:100%; height:100%; background-color: #f4f6f9;">-->
    <router-outlet></router-outlet>
</div>
<!-- Routed components go here -->
</div>