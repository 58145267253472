import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as $ from '../../../../node_modules/jquery';
import * as moment from 'moment'; // add this 1 of 4
import * as io from 'socket.io-client';
import * as Push from 'push.js';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { MessagingService } from '../../servicios/messaging.service';
import { UpperCasePipe } from '@angular/common';

const URL = localStorage.getItem('url');

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  constructor(private route: ActivatedRoute, private notificacion: MessagingService) { }

  ngOnInit(): void {

   // Push.default.create('Nuevo usuario !!!'); 
   this.sucursales();
    this.recuperar();
    this.telegram_users();
    $("#form1").submit(function(e) {
      e.preventDefault();
    });

  }

  async sucursales(){

   
    const response = await fetch( URL + '/garantia-sucursales');
    const data = await response.json();
    console.log(data);
    localStorage.setItem('lista-sucursales', JSON.stringify(data))
    let html = '<option value="0"></option>'; 
    data.forEach(item => {
      html+= `<option value="${item.id}">${item.nombre}</option>\n`
    });
    $("#idSucursal").html(html);
  }

  telegram_users(){
    // var servidor = window.location.origin; 
     var url = "" ; 
    // if (servidor.indexOf('localhost') >0 ){
    //   url = URL + "/telegram-users";
    // }else{
    //   url = servidor + "/telegram-users";
    // }

    url = URL + "/telegram-users";

    $.get(url, function (data, status) {
      console.log('estado de la consulta ', status);
      console.log(data);
    })    
    .done((data) => {
      if (data['rows'].length > 0) {
        var datos = data['rows'];
        var html = "";
        html = '<option value="0"></option>';
        for (let index = 0; index < datos.length; index++) {
          html += '<option value="'+ datos[index]['chat_id'] + '"> '+ datos[index]['first_name'] +' '+ datos[index]['last_name'] +' - '+ datos[index]['username'] +'</option>'; 
        } 
        $("#chat_id").append(html);
      }
    })
    .fail((err) => {
      //this.spinner(0); 
      console.error('hubo un error al traer los datos.. ' , err );
    });    
          
  }

  recuperar(){
    // var servidor = window.location.origin; 
     var url = "" ; 
    // if (servidor.indexOf('localhost') >0 ){
    //   url = URL + "/garantia-lista-usuarios"; 
    // }else{
    //   url = servidor + "/garantia-lista-usuarios"; 
    // }

    url = URL + "/garantia-lista-usuarios"; 

    $.get(url, function (data, status) {
      console.log('estado de la consulta ', status);
      console.log(data);
    })    
    .done((data) => {
      
      if (data['rows'].length > 0) {

        const listaSucursales = JSON.parse(localStorage.getItem('lista-sucursales'))
        console.log(listaSucursales)

        var datos = data['rows'];
        var body = "", nombreSucursal;
        for (let index = 0; index < datos.length; index++) {
          body += '<tr id="F'+ (index + 1) +'">';
          body += '<td>' + datos[index]['fila'] + '</td>' +
            '<td style="vertical-align:middle;">' + datos[index]['nombre'] + '</td>' +
            '<td style="vertical-align:middle;">' + datos[index]['perfil'] + '</td>'+
            '<td style="vertical-align:middle;">' + datos[index]['usuario'] + '</td>';
          if ( datos[index]['estado'].toUpperCase() == 'ACTIVO') {
            body += '<td style="text-align: center;"><span class="badge badge-success">' + datos[index]['estado'] + '</span></td>'
          } else {// cuando es nuevo 
            body += '<td style="text-align: center; vertical-align:middle;"><span class="badge badge-danger">' + datos[index]['estado'] + '</span></td>'
          }
          //si tiene asignado la notificacion de telegram 
          if(datos[index]['chat_id'] == null || datos[index]['chat_id'] == 0 ){
            body += '<td style="vertical-align:middle; text-align:center;" chat="0"><i class="fa fa-question-circle fa-2x text-warning" aria-hidden="true"></i></td>';
          }else{
            body += '<td style="vertical-align:middle; text-align:center;" chat="'+ datos[index]['chat_id'] +'"><i class="fa fa-paper-plane fa-2x text-success" aria-hidden="true"></i></td>';
          }
          nombreSucursal = listaSucursales.find(item=> item.id === datos[index]['idSucursal'] )?.nombre || ''
          body += '<td style="text-align: center; vertical-align:middle;">' + nombreSucursal + '</td>'
          body += `<td style="text-align: center; vertical-align:middle;"><button class="btn btn-info btn-sm elevation-1" id="B${index +1}" item="${datos[index]['id']}" datos="${JSON.stringify(datos[index]).replace(/["]/g,"/") }" pss="${datos[index]['pass']}" > <i class="fa fa-reply" aria-hidden="true"></i> </button> </td>`;

        }
        body += '</tr>';
        document.querySelector('#listadoUsuario').innerHTML = body;
        var registros = $("#listadoUsuario tr").length;
        for (let index = 0; index < registros; index++) {
            document.getElementById('B'+ (index +1 )).addEventListener('click', () => { this.editar( (index + 1 ) ) }); 
        }
      }          

    })
    .fail((err) => {
      //this.spinner(0); 
      console.error('hubo un error al traer los datos.. ' , err );
    });    
  }

  editar(fila){
    let valor = JSON.parse($("#B" + fila).attr("datos").replaceAll('/', '"'))
    //recorremos el form     
    for (const [key, value] of Object.entries(valor)) {
      $(`#${key}`).val(value)  
    }

    $("#nombre").attr('readonly', true);
    $("#usuario").attr('readonly', true);
    //$("#perfil").attr('readonly', true);
    $("#abm").text("Modificar");
    $("#nombre").focus();
    
  }

  reset(){
    $("#form1").trigger("reset");
    $("#nombre").attr('readonly', false);
    $("#usuario").attr('readonly', false);
    //$("#perfil").attr('readonly', false);
    $("#abm").text("Crear");
    $("#nombre").focus();
  }

  async actualizar() {

    try {
      var valor = $("#form1").serializeArray();
      var jsonData = valor.reduce((json,{name,value})=>{ json[name] = value; return json }, {})
      delete jsonData.id 
      console.log('valor json', JSON.stringify(jsonData))
      
      const res = await fetch(`${URL}/garantia-upd-usuario/${$("#id").val()}`,
                              {
                                method: 'PUT',
                                headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
                                body: JSON.stringify(jsonData) 
                              }
                            )
      const retorno = await res.text()
      console.log('retorno del backend', retorno)
      if(res.ok){
        console.log('registro grabado !' ,res )
        swal.fire('Atencion', 'Datos modificados correctamente !!!', 'success')
        this.recuperar();
        this.reset();
      }else{
        swal.fire('Atencion', retorno , 'error')
        return 
      }

    } catch (error) {
      console.log('error actualizar usuario ', error)
      swal.fire("error al actualizar usuario... ", error ,'error' )
      return;
    }

  }

  async crear(){
    if( $("#abm").text() == 'Modificar'){
      this.actualizar();
      //swal.fire('modificar');
      return ;
    }

    if( 
      $("#nombre").val().trim().length == 0  ||
      $("#usuario").val().trim().length == 0  ||
      $("#pass").val().trim().length == 0  ){
      swal.fire('Atencion','Debe ingresar los campos requeridos !!!', 'warning');
      return ;
    }

    if( $("#idSucursal").val().trim() == 0 ){
      swal.fire('Atencion','Debe asignar la sucursal !!!', 'warning');
      return ;
    }

    // var promesa = new Promise((resolve, reject)=> {

    //   var valor = $("#form1").serializeArray();
    //   var servidor = window.location.origin; 
    //   var url = "" ; 
    //   if (servidor.indexOf('localhost') >0 ){
    //     url = "http://192.168.10.65:3010/garantia-ins-usuario"; 
    //   }else{
    //     url = servidor + "/garantia-ins-usuario"; 
    //   }

    //   $.post(url, valor)
    //   .done(function (data) {
    //     swal.fire('Exito', 'Datos grabados correctamente !!!', 'success');
    //     resolve(1);
    //   })
    //   .fail(function (err){ 
    //     reject(0);
    //     swal.fire("error al actualizar cab... ", err ,'error' );
    //     return ;
    //   });
    // })
    // .then(value =>{
    //   this.recuperar();
    //   this.reset();
    // }) 

    try {
      var valor = $("#form1").serializeArray();
      var jsonData = valor.reduce((json,{name,value})=>{ json[name] = value; return json }, {})
      console.log('valor json', JSON.stringify(jsonData))
      const res = await fetch(`${URL}/garantia-ins-usuario`,
                              {
                                method: 'POST',
                                headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
                                body: JSON.stringify(jsonData) 
                              }
                            )
      const retorno = await res.text()
      if(res.ok){
        console.log('registro grabado !' ,res )
        swal.fire('Atencion', 'Datos grabados correctamente !!!', 'success')
        this.recuperar();
        this.reset();
      }else{
        swal.fire('Atencion', retorno , 'error')
        return 
      }

    } catch (error) {
      console.log('error insertar usuario ', error)
      swal.fire("Atencion","Error al insertar usuario... " +  error ,'error' )
      return;
    }
  }//end crear 


}
