import { Component, OnInit, ElementRef } from '@angular/core';
//import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import * as $ from '../../../../node_modules/jquery';

import '../../../../node_modules/jquery-ui-dist/jquery-ui.min.css';
import '../../../../node_modules/jquery-ui-dist/jquery-ui.min.js';
import '../../../../node_modules/pivottable/dist/pivot.min.css';
import '../../../../node_modules/pivottable/dist/pivot.min.js';

//para el listado de reportes... 
import '../../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
import '../../../../node_modules/@fortawesome/fontawesome-free/js/all.min.js';
import '../../../../node_modules/admin-lte/dist/js/adminlte.min.js';
import '../../../../node_modules/admin-lte/dist/css/adminlte.min.css';

import '../../../../node_modules/bootstrap-table/dist/bootstrap-table.min.css'
import '../../../../node_modules/bootstrap-table/dist/bootstrap-table.min.js'

import '../../../../node_modules/jsgrid/dist/jsgrid.min.css'
import '../../../../node_modules/jsgrid/dist/jsgrid-theme.min.css'
import '../../../../node_modules/jsgrid/dist/jsgrid.min.js'



import * as moment from 'moment'; // add this 1 of 4
import * as io from 'socket.io-client';
import swal from'sweetalert2';
import { Toast, ToastrService } from 'ngx-toastr';
import { WebsocketService } from 'src/app/servicios/websocket.service';

const URL = localStorage.getItem('url');
@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $("#fechai").val(moment().format('yyyy-MM-01'));
    $("#fechaf").val(moment().format('yyyy-MM-DD'));

  }


  showLoading = function() {
    swal.fire({
        title: 'Please Wait',
        allowEscapeKey: false,
        allowOutsideClick: false,
        background: '#19191a',
        showConfirmButton: false,
        onOpen: ()=>{
            swal.showLoading();
        }
        // timer: 3000,
        // timerProgressBar: true
    });
  };

  async buscar(){
    var fechai = $("#fechai").val();
    var fechaf = $("#fechaf").val();
    var sucursal = $("#sucursales").val();
    
    this.showLoading()
    await fetch(`${URL}/garantia-reportes/${fechai}/${fechaf}/${sucursal}`)
    .then(response => response.json())  // convertir a json
    .then((json)=>{
      swal.close()//cerrar spin

      let datafields = [], datafields2 = "", columns =[]
      Object.entries(json[0]).forEach(item => datafields.push( {name: item[0], type: typeof item[1]} )) 
      json.forEach(item =>{
        datafields2 +="<tr>\n" 
        Object.entries(item).forEach(x => datafields2 += `<td class="${x[0]}">${String(x[1]).toUpperCase()}</td> \n`) 
        datafields2+="</tr>\n"
      }) 

      Object.entries(json[0]).forEach(item => columns.push( {text: item[0], datafield: item[0], width: 170, aggregates: typeof item[1] === 'number'? ['sum'] : item[0]==='despacho' ? ['count']: []} )) 

      if(json.length > 0 ){
        
        console.log(json)
        let pivotConfig = JSON.parse(localStorage.getItem('pivot-config')) || 
         {
          rows: ["area"],
          cols: ["estado"],
          rendererName: "Table",
          colOrder: "key_a_to_z",
          rowOrder: "key_a_to_z"
        }
        $("#res").pivotUI(json,
           {
            onRefresh: function(config) {
              localStorage.setItem('pivot-config', JSON.stringify(
                {
                  rows: config.rows,
                  cols: config.cols,
                  rendererName: config.rendererName,
                  colOrder: config.colOrder,
                  rowOrder: config.rowOrder
                }
              ))
            }, 
               rows: pivotConfig?.rows || ["area"],
               cols: pivotConfig?.cols || ["estado"],
               colOrder: pivotConfig?.colOrder || "key_a_to_z",
               rowOrder: pivotConfig?.rowOrder || "key_a_to_z",
               rendererName: pivotConfig?.rendererName || "Table"
           }); 
           let columnas = Object.keys(json[0]).map(item=> { return {field: item, title: item , sortable: true} })
           
           let columnas2 = "<tr>\n"
           Object.keys(json[0]).forEach(item=>{ columnas2 += `<th class="${item}">${item} <br> <input id="${item}" type="text" class="form-control" /> </th> \n` })
           columnas2 +=  "\n </tr> \n"

           $('#table').bootstrapTable({
            pagination: false,
            search: true,
            exportTypes: [ 'csv', 'txt', 'excel', 'pdf'],
            columns: columnas, 
            data:json
            })
            $(".fixed-table-loading").css('visibility', 'hidden')
            $("#cab-listado2").html(columnas2)
            $("#det-listado2").html(datafields2)
            Object.keys(json[0]).forEach(item=>{ 
              document.getElementById(item).addEventListener('change', (valor) => { 
                if($("#"+item).val().length === 0 ){ 
                  $(`tbody tr td.${item}`).parent().show() 
                  console.log('valores true ', $(`tbody tr td.${item}`).parent().show() )
                }
                $(`tbody tr td.${item}`).not(`:contains("${$("#"+item).val().toUpperCase()}")`).parent().hide()

                $("#totalRegistros").text($('table tbody tr:visible').length )
               
                setTimeout(() => {
                  //TODO ESTE SCRIPT PARA REALIZAR CONTEO DEL FILTRADO
                  let list = [],  datos = [] , valor = {}
                  $('table tbody tr:visible').each((x, element) => {
                    list.push(element)
                  })
                  $(list).each((x,item) => {
                      $(item.children).each((x, item) => valor[item.className] = item.innerText )
                      datos.push(valor)
                      valor = {}
                  })
                  console.log(datos)
                  $("#totalGarantia").text( datos.filter(item=> item.area === 'GARANTIA').length )
                  $("#totalGaAprobados").text( datos.filter(item=> item.area === 'GARANTIA' && item.estado === 'APROBADO').length )
                  $("#totalGaPendientes").text( datos.filter(item=> item.area === 'GARANTIA' && item.estado === 'PENDIENTE').length )
  
                  $("#totalTaller").text( datos.filter(item=> item.area === 'TALLER' ).length )
                  $("#totalTaPendientes").text( datos.filter(item=> item.area === 'TALLER' && item.estado === 'PENDIENTE').length )
      
                  $("#totalHoy").text( datos.filter(item=> moment(item.FSolicitud).format("YYYY-MM-DD") === moment().format('YYYY-MM-DD') ).length )
                  $("#totalHoyAprobados").text( datos.filter(item=> moment(item.FSolicitud).format("YYYY-MM-DD") === moment().format('YYYY-MM-DD') && item.estado === 'APROBADO'  ).length )
                  $("#totalHoyPendientes").text( datos.filter(item=> moment(item.FSolicitud).format("YYYY-MM-DD") === moment().format('YYYY-MM-DD') && item.estado === 'PENDIENTES' ).length )
                  $("#totalHoyCerrado").text( datos.filter(item=> moment(item.FSolicitud).format("YYYY-MM-DD") === moment().format('YYYY-MM-DD') && item.estado === 'CERRADO' ).length )

                  }, 500);

              })
            })


            //contar los registos de vuleta 
            $("#totalRegistros").text( json.length )

            $("#totalGaAprobados").text( json.filter(item=> item.area === 'GARANTIA' && item.estado === 'APROBADO').length )
            $("#totalGaPendientes").text( json.filter(item=> item.area === 'GARANTIA' && item.estado === 'PENDIENTE').length )
            $("#totalGarantia").text( json.filter(item=> item.area === 'GARANTIA' ).length )

            $("#totalTaPendientes").text( json.filter(item=> item.area === 'TALLER' && item.estado === 'PENDIENTE').length )
            $("#totalTaller").text( json.length )

            $("#totalHoyAprobados").text( json.filter(item=> item.area === 'GARANTIA' && item.estado === 'APROBADO' && moment(item.FSolicitud).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ).length )
            $("#totalHoyPendientes").text( json.filter(item=> item.area === 'GARANTIA' && item.estado === 'PENDIENTE' && moment(item.FSolicitud).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ).length )
            $("#totalHoyCerrado").text( json.filter(item=> moment(item.FSolicitud).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && item.estado === 'CERRADO' ).length )
            $("#totalHoy").text( json.filter(item=> moment(item.FSolicitud).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ).length )

      }else{
        alert('No existen Registros !!!')
      }
    })  
    .catch(err => {
      swal.close()
      console.log('Solicitud fallida', err)
      alert('Hubo un error en la consulta')
    }); // Capturar errores
  }

  filtrar(target){
    alert(target)
    console.log('valor de busqueda', target)
    //$('tbody tr td.vehiculo').not(':contains("SOLUTO")').parent().hide()
  }

  resetFiltro(){
    $("#fechai").val(moment().format('yyyy-MM-01'));
    $("#fechaf").val(moment().format('yyyy-MM-DD'));

  }
}
