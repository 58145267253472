import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImpresionComponent } from './componentes/impresion/impresion.component';
import { LoginComponent } from './componentes/login/login.component';
import { PendientesComponent } from './componentes/pendientes/pendientes.component';
import { ReportesComponent } from './componentes/reportes/reportes.component';
import { SolitidudComponent } from './componentes/solitidud/solitidud.component';
import { TableroComponent } from './componentes/tablero/tablero.component';
import { UsuariosComponent } from './componentes/usuarios/usuarios.component';
import { AuthGuardService } from './servicios/auth-guard.service';
import { GarantiaGuard } from './servicios/garantia.guard';
import { LoginGuardGuard } from './servicios/login-guard.guard';
import { SolicitudGuard } from './servicios/solicitud.guard';
import { DocumentacionComponent } from './componentes/documentacion/documentacion.component';
import { SucursalesComponent } from './componentes/sucursales/sucursales.component';

const routes: Routes = [
  { path: 'garantia/tablero', component: TableroComponent , canActivate:[AuthGuardService, GarantiaGuard]  },
  { path: 'garantia/login', component: LoginComponent , canActivate: [LoginGuardGuard]},
  { path: 'garantia/pendientes', component: PendientesComponent, canActivate: [AuthGuardService] },
  { path: 'garantia/impresion', component: ImpresionComponent, canActivate: [AuthGuardService] },
  { path: 'garantia/solicitud/:id', component: SolitidudComponent , canActivate:[AuthGuardService, SolicitudGuard] },
  { path: 'garantia/usuarios', component: UsuariosComponent , canActivate:[AuthGuardService] },
  { path: 'garantia/reportes', component: ReportesComponent , canActivate:[AuthGuardService] },
  { path: 'garantia/sucursales', component: SucursalesComponent , canActivate:[AuthGuardService] },
  { path: 'garantia/documentacion', component: DocumentacionComponent },
  { path: '**', redirectTo: 'garantia/tablero' },
  { path: '', redirectTo: 'garantia/tablero', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
