import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as $ from '../../../../node_modules/jquery';
import * as moment from 'moment'; // add this 1 of 4
import * as io from 'socket.io-client';
import * as Push from 'push.js';
import swal from 'sweetalert2';
import { ToastrService } from 'ngx-toastr';
import { MessagingService } from '../../servicios/messaging.service';
const URL = localStorage.getItem('url');

@Component({
  selector: 'app-sucursales',
  templateUrl: './sucursales.component.html',
  styleUrls: ['./sucursales.component.css']
})
export class SucursalesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.recuperar()
    $("#form1").submit(function(e) {
      e.preventDefault();
    });

  }


  recuperar(){
    var url = "" ; 
    url = URL + "/garantia-sucursales"; 

    $.get(url, function (data, status) {
      console.log('estado de la consulta ', status);
      console.log(data);
    })    
    .done((data) => {
      
      if (data.length > 0) {
        var datos = data;
        var body = ""
        for (let index = 0; index < datos.length; index++) {
          body += '<tr id="F'+ (index + 1) +'">';
          body += '<td>' + datos[index]['id'] + '</td>' +
            '<td style="vertical-align:middle;">' + datos[index]['nombre'] + '</td>' 

          if ( datos[index]['estado'] == '1') {
            body += '<td style="text-align: center;"><span class="badge badge-success">ACTIVO</span></td>'
          } else {// cuando es nuevo 
            body += '<td style="text-align: center; vertical-align:middle;"><span class="badge badge-danger">INACTIVO</span></td>'
          }
          body += `<td style="text-align: center; vertical-align:middle;"><button class="btn btn-info btn-sm elevation-1" id="B${index +1}" item="${datos[index]['id']}" datos="${JSON.stringify(datos[index]).replace(/["]/g,"/") }" > <i class="fa fa-reply" aria-hidden="true"></i> </button> </td>`;

        }
        body += '</tr>';
        document.querySelector('#listadoSucursal').innerHTML = body;
        var registros = $("#listadoSucursal tr").length;
        for (let index = 0; index < registros; index++) {
          document.getElementById('B'+ (index +1 )).addEventListener('click', () => { this.editar( (index + 1 ) ) }); 
        }
      }

    })
    .fail((err) => {
      //this.spinner(0); 
      console.error('hubo un error al traer los datos.. ' , err );
    });    
  }

  editar(fila){

    let valor = JSON.parse($("#B" + fila).attr("datos").replaceAll('/', '"'))
    //recorremos el form     
    console.log(valor)
    for (const [key, value] of Object.entries(valor)) {
      $(`#${key}`).val(value)  
    }

    $("#id").attr('readonly', true);
    $("#abm").text("Modificar");
    $("#nombre").focus();
  }

  reset(){
    $("#form1").trigger("reset");
    $("#abm").text("Crear");
    $("#nombre").focus();
  }

  async actualizar(){
    if( $("#nombre").val().trim().length == 0 || $("#id").val().trim().length == 0 ){
      swal.fire('Atencion','Debe ingresar los campos requeridos !!!', 'warning');
      return ;
    }

    try {
      var valor = $("#form1").serializeArray();
      console.log(valor)
      var jsonData = valor.reduce((json,{name,value})=>{ json[name] = value; return json }, {})
      console.log('valor json', JSON.stringify(jsonData))
      
      const res = await fetch(`${URL}/garantia-sucursales/${$("#id").val().trim()}`,
                              {
                                method: 'PUT',
                                headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
                                body: JSON.stringify(jsonData) 
                              }
                            )
      const retorno = await res.text()
      if(res.ok){
        console.log('registro actualizado !' ,res )
        swal.fire('Atencion', 'Registro Actualizado !!!', 'success')
        this.recuperar();
        this.reset();
      }else{
        swal.fire('Atencion' , retorno,  'error')
        return 
      }

    } catch (error) {
      console.log('error insertar usuario ', error)
      swal.fire("Atencion","Error al insertar usuario... " +  error ,'error' )
      return;
    }
    
  }

  async crear(){
    if( $("#abm").text() == 'Modificar'){
      this.actualizar();
      return ;
    }

    if( $("#nombre").val().trim().length == 0  ){
      swal.fire('Atencion','Debe ingresar los campos requeridos !!!', 'warning');
      return ;
    }

    try {
      var valor = $("#form1").serializeArray();
      console.log(valor)
      var jsonData = valor.reduce((json,{name,value})=>{ json[name] = value; return json }, {})
      console.log('valor json', JSON.stringify(jsonData))

      const res = await fetch(`${URL}/garantia-sucursales`,
                              {
                                method: 'POST',
                                headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
                                body: JSON.stringify(jsonData) 
                              }
                            )
      const retorno = await res.text()
      if(res.ok){
        console.log('registro grabado !' ,res )
        swal.fire('Atencion', 'Datos grabados correctamente !!!', 'success')
        this.recuperar();
        this.reset();
      }else{
        swal.fire('Atencion', retorno , 'error')
        return 
      }

    } catch (error) {
      console.log('error insertar usuario ', error)
      swal.fire("Atencion","Error al insertar usuario... " +  error ,'error' )
      return;
    }
  }//end crear 


}
